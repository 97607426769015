.scrollbar {
  // overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    &-thumb {
      background-color: var(--chakra-colors-blue-400);
    }
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}
