.scroll-spy {
  &__link {
    display: block;
    cursor: pointer;
    font-size: var(--chakra-fontSizes-xs);
    white-space: nowrap;

    &.active {
      font-weight: bold;
      color: var(--chakra-colors-brand-400);
    }

    &:hover {
      font-weight: bold;
    }
  }
}
