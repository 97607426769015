.table-sticky {
  thead {
    th {
      background: var(--chakra-colors-gray-50);
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}
